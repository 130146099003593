import { useEffect, useRef, useState } from "react";
import BlockQuestionsComponent from "./BlockQuestionsComponent";
import { useDrag, useDrop } from "react-dnd";
import styles from "./Styles.module.css";

const BlockList = ({
  blockQuestions,
  hideBlock,
  setBlockQuestions,
  setEditMode,
  setAlertInfo,
  index,
  setEditModeType,
  setIndexForEdit,
  hideElFunction,
  el,
  moveBlockListItem,
}) => {
  const [currentIndex, setCurrentIndex] = useState(null);
  const [{ isDragging, canDrag }, dragRef] = useDrag({
    type: "item",
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      canDrag: monitor.canDrag(),
    }),
  });

  useEffect(() => {
    setCurrentIndex(null);
  }, [el]);
  useEffect(() => {
    if (!isDragging) {
      setCurrentIndex(null);
    }
  }, [isDragging]);

  // useDrop - the list item is also a drop area
  const [spec, dropRef] = useDrop({
    accept: "item",
    hover: (item, monitor) => {
      setCurrentIndex(item.index);
      const dragIndex = item.index;
      const hoverIndex = index;
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top;

      // if dragging down, continue only when hover is smaller than middle Y
      if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return;
      // if dragging up, continue only when hover is bigger than middle Y
      if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return;

      moveBlockListItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  // Join the 2 refs together into one (both draggable and can be dropped on)
  const ref = useRef(null);
  const dragDropRef = dragRef(dropRef(ref));
  // const opacity = isDragging ? 0 : 1;

  return (
    <div
      style={{ opacity: currentIndex == index && !canDrag ? 0.2 : 1 }}
      className={styles.block_container}
    >
      <div ref={dragDropRef} className={styles.header_block_name_line}>
        <div>
          <span
            onClick={() => {
              hideElFunction(index);
            }}
          >
            {blockQuestions[index].questions.length ? (
              <>{hideBlock.includes(index) ? "+" : "—"} </>
            ) : null}
          </span>
          <h2>{el.caseName}</h2>
          {blockQuestions[index].questions.length ? (
            <>
              <img
                onClick={() => {
                  if (setBlockQuestions == null) return;
                  setEditMode(true);
                  setIndexForEdit(index); // для того чтобы передать правильный индекс в компоненту для редактирования
                  setEditModeType("block_name");
                }}
                src={require("../../../Images/Button_opacity.png")}
                alt="Редактирование названия блока вопросов"
              />
              <img src={require("../../../Images/menu.png")} alt="" />
            </>
          ) : null}
        </div>
        {blockQuestions[index].questions.length ? (
          <>
            {index > 0 && (
              <img
                style={{
                  cursor: "pointer",
                  marginBottom: "auto",
                }}
                src={require("../../../Images/Button_delete.png")}
                alt=""
                onClick={() => {
                  setBlockQuestions(
                    (
                      prev // удаляет выбранный блок
                    ) => prev.filter((el, num) => (num > 0 ? num != index : el))
                  );
                }}
              />
            )}
          </>
        ) : null}
      </div>
      <div style={hideBlock.includes(index) ? { display: "none" } : {}}>
        <BlockQuestionsComponent
          hideBlock={hideBlock}
          setAlertInfo={setAlertInfo}
          setEditMode={setEditMode}
          blockQuestions={blockQuestions}
          setBlockQuestions={setBlockQuestions}
          index={index}
        />
      </div>
    </div>
  );
};

export default BlockList;
