import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styles from "./style.module.css";
import { URL, getModerationState, moderation } from "../../DAL/dal";
import { useNavigate, useParams } from "react-router-dom";
import { setSessionIdAction } from "../../Redux/QuestionsReducer";
import { useDispatch } from "react-redux";
import { AlertModal } from "../AdminPage/HtmlElements/Elements";
import { app } from "../../App";
const shortid = require("shortid"); // генерация уникальных ключей для вопросов

const AskSpeakerComponent = ({
  socket,
  questionsToLector,
  setQuestionsToLector,
  questionNameColor,
  buttonsColor,
  clientTextColor,
  containerColor,
  setEditMode,
  editMode,
  setEditModeType,
  height,
  width,
  socketId,
}) => {
  const [textAreaState, setTextAreaState] = useState({
    // Для управления textarea
    rowsCount: 1,
    scrollHeight: 0,
    prevHeight: 0,
  });
  const [alertInfo, setAlertInfo] = useState("");
  const [askInput, setAskInput] = useState("");
  const [isPersonalData, setIsPersonalData] = useState(false); // отвечает за вывод формы ввода имени и фамилии
  const [personalData, setPersonalData] = useState({
    first_name: "",
    last_name: "",
  });
  const [whoIsModeration, isWhoIsModeration] = useState(false); // кто модерирует для отображения состояния ниже
  const [isModeration, setIsModeration] = useState(false); // состояние отвечающее за появление уведомления о модерации
  const [checkPrivatePolice, setCheckPrivatePolice] = useState(true);

  const ref = useRef();

  const askQuestion = useCallback(
    // задача функции вернуть объект вопроса для спикера
    (seed, personalData, shortid) => ({
      question: seed,
      isAnswered: false,
      range: 0,
      first_name: personalData.first_name,
      last_name: personalData.last_name,
      key: shortid.generate(),
    }),
    []
  );

  useEffect(() => {
    const key = window.localStorage.getItem("isFirstToAsk");
    if (!key && isPersonalData) {
      setAlertInfo(
        "Здравствуйте, на текущем экране у Вас есть возможность задавать вопросы спикеру, также Вы видите ранее заданные вопросы и можете голосовать за них продвигая их в ТОП. Вопросы должны относится к текущей тематике лекции и могут модерироваться интеллектуальным ассистентом CongressGO. Поэтому если Вы задаете тактичный вопрос, но ассистент его не пропускает, то попробуйте перефразировать текст и задать вопрос еще раз и пожалуйста не сердитесь на робота, он только учится)"
      );
      setEditMode(true);
    }
  }, [isPersonalData]);

  const containerRef = useRef(null); // для автоматического скролла вниз

  useEffect(() => {
    if (window.localStorage.getItem("FI")) {
      const data = window.localStorage.getItem("FI").split(",");
      setPersonalData((state) => ({
        ...state,
        first_name: data[0],
        last_name: data[1],
      }));
      if (data[0] && data[1]) setIsPersonalData(true);
    }
  }, []);

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const typeFI = (e) => {
    setPersonalData((state) => ({ ...state, [e.target.name]: e.target.value }));
  };

  const sendToLocalStorage = () => {
    const { first_name, last_name } = personalData;
    if (!first_name && !last_name) {
      setAlertInfo("Заполните пожалуйста имя и фамилию");
      setEditMode(true);
    } else if (!first_name) {
      setAlertInfo("Заполните пожалуйста имя");
      setEditMode(true);
    } else if (!last_name) {
      setAlertInfo("Заполните пожалуйста фамилию");
      setEditMode(true);
    } else {
      if (window.localStorage.getItem("FI")) {
        const data = window.localStorage.getItem("FI").split(",");
        data[0] = first_name;
        data[1] = last_name;
        window.localStorage.setItem("FI", data);
        setIsPersonalData(true);
      } else {
        window.localStorage.setItem("FI", [`${first_name},${last_name}`]);
        setIsPersonalData(true);
      }
    }
  };

  useEffect(() => {
    containerRef?.current?.scrollIntoView({ behavior: "smooth" });
  }, [questionsToLector.length]);

  useEffect(() => {
    dispatch(setSessionIdAction(id)); // получаем id из параметров адресной строки
  }, [id]);

  const addQuestion = () => {
    const seed = askInput;
    if (askInput) {
      getModerationState(id).then((r) => {
        isWhoIsModeration(r);
        setIsModeration(true);
        // здесь мы определяем кому направлять модерацию в chatgpt или человеку
        if (r) {
          // отправляем на модерацию в chatgpt
          moderation(askInput, id).then((r) => {
            if (r.toLowerCase() == "true") {
              socket.emit("setQuestion", {
                question: askQuestion(seed, personalData, shortid),
                id,
              });
              return setIsModeration(false);
            }
            if (r.toLowerCase() == "false") {
              setAlertInfo("Ваш вопрос не прошел модерацию");
              setEditMode(true);
            } else {
              setAlertInfo("Повторите Ваш вопрос");
              setEditMode(true);
            }
            setIsModeration(false);
          });
        } else {
          // отправляем на модерацию человеку
          socket.emit("sendToHumanForModeration", {
            question: askQuestion(seed, personalData, shortid),
            id,
          });
          setIsModeration(false);
          setAlertInfo("Ваш вопрос отправлен модератору");
          setEditMode(true);
        }
      });
      setAskInput("");
    } else {
      setAlertInfo("Введите Ваш вопрос");
      setEditMode(true);
    }
  };

  const rangeUp = (e, index) => {
    const storage = window.localStorage.getItem("rangeUp");
    if (storage) {
      if (storage.split(",").find((el) => el == questionsToLector[index].key))
        return;
    }
    const key = questionsToLector.find(
      (el) => el.key == questionsToLector[index].key
    ).key;
    socket.emit("setRange", {
      my_id: socketId,
      key,
      currentId: id,
      x: (100 / window.innerWidth) * e.clientX,
      y: (100 / window.innerHeight) * e.clientY,
    });
  };

  return (
    <>
      <section
        className={styles.for_alert_modal}
        style={{
          width: editMode ? "100vw" : 0,
          height: height,
        }}
      >
        {editMode && (
          <AlertModal
            info={alertInfo}
            setEditMode={setEditMode}
            setEditModeType={setEditModeType}
            ancillary={alertInfo.length > 460 ? true : false}
          />
        )}
      </section>
      {!editMode && (
        <>
          {!isPersonalData ? (
            <section
              className={styles.main_personal_data}
              style={{ background: containerColor }}
            >
              <span
                style={{
                  cursor: "pointer",
                  margin: "10px 0 25px 0",
                  display: "block",
                }}
                onClick={() => navigate(`/${id}`)}
              >
                ← Назад
              </span>
              <h2>Ваше имя и фамилия</h2>
              <div className={styles.personal_inputs_and_button}>
                <input
                  type="text"
                  name="first_name"
                  value={personalData.first_name}
                  onChange={typeFI}
                  placeholder="Имя"
                />

                <input
                  type="text"
                  name="last_name"
                  value={personalData.last_name}
                  onChange={typeFI}
                  placeholder="Фамилия"
                />
                <div className={styles.private_police}>
                  <div
                    onClick={() => setCheckPrivatePolice(!checkPrivatePolice)}
                  >
                    <div>
                      <span
                        style={{
                          transform: checkPrivatePolice
                            ? ""
                            : "translateX(-25px)",
                        }}
                      >
                        ✓
                      </span>
                    </div>
                    <span>Согласие на обработку персональных данных</span>
                  </div>
                  <a
                    href={URL + `static_information/private_police.pdf`}
                    target="_blank"
                  >
                    Политика конфеденциальности
                  </a>
                </div>
                <button
                  style={{
                    background: buttonsColor[0],
                    color: buttonsColor[1],
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (!checkPrivatePolice) {
                      setAlertInfo(
                        "Подтвердите согласие на обработку персональных данных"
                      );
                      return setEditMode(true);
                    }

                    sendToLocalStorage();
                  }}
                  className={styles.set_personal_button}
                >
                  Войти
                </button>
              </div>
            </section>
          ) : (
            <section
              className={styles.main_ask}
              style={{ marginBottom: textAreaState.prevHeight ? "auto" : "" }}
            >
              {/* <div onClick={() => deleteAllQuestions(id)}>Удалить все</div> */}
              <div
                ref={ref}
                className={styles.questions_pool}
                style={{
                  background: containerColor,
                  height:
                    width < 700 && width > 480 ? height * 0.78 : height * 0.75,
                }}
              >
                <div
                  style={{
                    cursor: "pointer",
                    margin: "10px 0 20px 0",
                    display: "block",
                  }}
                  onClick={() => navigate(`/${id}`)}
                >
                  ← Назад
                </div>
                <h2 style={{ color: clientTextColor }}>Вопросы участников</h2>
                <div
                  className={styles.scroll_questions_pool}
                  style={{ overflowY: "auto", height: "75%" }}
                >
                  {questionsToLector.map((el, index) => {
                    if (!el.isAnswered)
                      // не показываем те вопросы на которые лектор уже давал ответ
                      return (
                        <div
                          className={styles.question_element}
                          style={{
                            background: index % 2 ? "#3d3d3d88" : "none",
                          }}
                          key={index}
                        >
                          <div
                            className={styles.question}
                            style={{ color: questionNameColor }}
                          >
                            {el.question}
                          </div>
                          <div
                            onClick={(e) => rangeUp(e, index)}
                            className={styles.range_botton}
                            style={{
                              color: buttonsColor[1],
                              background: buttonsColor[0],
                            }}
                          >
                            {!window.localStorage
                              .getItem("rangeUp")
                              ?.split(",")
                              ?.find((key) => key == el.key) ? (
                              <img src={require("../../Images/star.png")} />
                            ) : (
                              <img
                                className={styles.star_check}
                                src={require("../../Images/star-bold.png")}
                              />
                            )}
                            <span>Голосовать</span>
                            <span className={styles.point}></span>
                            <span>{el.range}</span>
                          </div>
                        </div>
                      );
                  })}
                  <div ref={containerRef}></div>
                </div>
              </div>
              <div className={styles.ask_input_and_send_button}>
                <textarea
                  className={styles.question_textarea}
                  rows={textAreaState.rowsCount}
                  type="text"
                  value={askInput}
                  onFocus={() => {
                    if (app.platform === "ios") {
                      const middle = ref.current.style.height;
                      setTextAreaState((state) => ({
                        ...state,
                        prevHeight: middle,
                      }));
                      ref.current.style.height = "40vh";
                    }
                  }}
                  onBlur={() => {
                    if (app.platform === "ios") {
                      ref.current.style.height = textAreaState.prevHeight;
                      setTextAreaState((state) => ({
                        ...state,
                        prevHeight: 0,
                      }));
                    }
                  }}
                  placeholder={!isModeration ? "Напишите Ваш вопрос" : ""}
                  style={{ background: containerColor }}
                  onChange={(e) => {
                    if (textAreaState.scrollHeight == 0)
                      setTextAreaState((state) => ({
                        ...state,
                        scrollHeight: e.target.scrollHeight,
                      }));
                    if (
                      e.target.scrollHeight > textAreaState.scrollHeight &&
                      textAreaState.scrollHeight > 0
                    ) {
                      setTextAreaState((state) => ({
                        ...state,
                        scrollHeight: e.target.scrollHeight,
                        rowsCount: (state.rowsCount += 1),
                      }));
                    }
                    setAskInput(e.target.value);
                  }}
                />
                <div className={styles.preloader}>
                  {isModeration && whoIsModeration ? (
                    <>
                      <div></div>
                      <span style={{ color: questionNameColor }}>
                        Автоматическая модерация
                      </span>
                    </>
                  ) : null}
                </div>
                <button
                  style={{
                    color: buttonsColor[1],
                    background: buttonsColor[0],
                  }}
                  onClick={addQuestion}
                >
                  Задать вопрос
                </button>
              </div>
            </section>
          )}
        </>
      )}
    </>
  );
};

export default AskSpeakerComponent;
