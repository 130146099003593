import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./telegram.module.css";
import {
  getAllTelegramContacts,
  sendNewMessage,
  sendTelegramImage,
} from "../../../../DAL/dal";

const TelegramInterface = ({ fastId, id, setEditMode, setInfoForAlert }) => {
  const [init, setInit] = useState(false); // если в базе никого нет то и рассылки показывать не стоит
  const [senderCount, setSenderCount] = useState(0);
  const [onLoad, setOnLoad] = useState(false);
  const telegramPhoto = useRef();
  const [photoState, setPhotoState] = useState({
    photoForSend: null,
    previewPhoto:
      "https://api-congress.pointreg.ru/static_information/upload.png",
  });

  useEffect(() => {
    getAllTelegramContacts(id)
      .then((r) => {
        if (!r.length) return; // если вообще не найдена сущность в таблице (для старых сессий по сути)
        setSenderCount(r[0].open_chats.length);
        setInit(true);
      })
      .catch((err) => {
        setInit(true);
        console.log(err);
      });
  }, []);

  const addTelegramPhoto = (e) => {
    setPhotoState((state) => ({
      ...state,
      photoForSend: telegramPhoto.current.files[0],
      previewPhoto: URL.createObjectURL(e.target.files[0]),
    })); // получает фото для отправки поста
  };
  const [sendObj, setSendObj] = useState({
    text: "",
    isSpoiler: false,
    buttons: [],
    isSetName: false,
  });
  const style = useCallback(
    // что не загромождать разметку стилями по условию
    (index, option) => {
      if (index === 1)
        return {
          transform: sendObj[option] ? "translateX(0)" : "translateX(50px)",
        };
      else
        return {
          opacity: sendObj[option] ? "1" : ".3",
        };
    },
    [sendObj]
  );

  // console.log(/(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/.test('https://prod-case-v3.vercel.app/#/'))

  const changeSendObj = useCallback(
    (e) => {
      switch (e.target.textContent) {
        case "Имя пользователя ✓":
          setSendObj((state) => ({
            ...state,
            isSetName: !state.isSetName,
            text: !state.isSetName
              ? "Иван" + state.text
              : state.text.replace("Иван", ""),
          }));
          break;
        case "Спойлер эффект ✓":
          setSendObj((state) => ({ ...state, isSpoiler: !state.isSpoiler }));
          break;
        case "Добавить кнопку":
          setSendObj((state) => ({
            ...state,
            buttons: [
              ...state.buttons,
              [
                {
                  text: "",
                  callback_data: `${sendObj.buttons.length}`,
                  url: "",
                },
              ],
            ],
          }));
          break;
        default:
          break;
      }
    },
    [sendObj]
  );

  const sendDataToBackEnd = useCallback(() => {
    setOnLoad(true);
    const { text, isSpoiler, buttons, isSetName } = sendObj;
    let content; // для того чтобы удалить example имя из текста
    if (sendObj.isSetName) content = text.replace("Иван", "");
    else content = text;
    if (!photoState.photoForSend) {
      console.log(buttons);
      sendNewMessage(id, content, JSON.stringify(buttons), isSetName)
        .then((r) => {
          setOnLoad(false);
          setEditMode(true);
          setInfoForAlert("Сообщение отправлено!");
        })
        .catch((err) => {
          setOnLoad(false);
          setEditMode(true);
          setInfoForAlert("Что то пошло не так...(((");
          console.log(err);
        });
      return;
    }
    sendTelegramImage(
      id,
      photoState.photoForSend,
      content,
      isSpoiler,
      JSON.stringify(buttons),
      isSetName
    )
      .then((r) => {
        setOnLoad(false);
        setEditMode(true);
        setInfoForAlert("Пост опубликован");
      })
      .catch((e) => {
        setOnLoad(false);
        setEditMode(true);
        setInfoForAlert("Что то пошло не так...(((");
        console.log(e);
      });
  }, [sendObj, photoState.photoForSend]);

  return (
    <>
      {init ? (
        <div className={styles.telegram_component}>
          <div className={styles.head}>
            <h2>Для Telegram</h2>
            <span>Короткий код: {fastId}</span>
          </div>

          <section style={{ height: senderCount ? "" : "0" }}>
            <div>
              {photoState.photoForSend ? (
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setPhotoState((state) => ({
                      ...state,
                      photoForSend: null,
                      previewPhoto:
                        "https://api-congress.pointreg.ru/static_information/upload.png",
                    }))
                  }
                >
                  ✕
                </span>
              ) : null}
              <label
                className={styles.photo_container}
                htmlFor="upload_telegram_photo"
              >
                <img src={photoState.previewPhoto} alt="" />
                <input
                  name="upload_telegram_photo"
                  id="upload_telegram_photo"
                  style={{ display: "none" }}
                  accept=".jpg,.jpeg,.png"
                  type="file"
                  ref={telegramPhoto}
                  onChange={addTelegramPhoto}
                />
                <div>Добавить изображение</div>
              </label>
            </div>
            <textarea
              className={styles.post_content}
              type="text"
              value={sendObj.text}
              onChange={(e) =>
                setSendObj((state) => ({ ...state, text: e.target.value }))
              }
            />
            <div className={styles.post_options}>
              <span
                onClick={changeSendObj}
                style={style(2, "isSetName")}
                className={styles.option}
              >
                Имя пользователя <span style={style(1, "isSetName")}>✓</span>
              </span>
              {photoState.photoForSend ? (
                <span
                  onClick={changeSendObj}
                  style={style(2, "isSpoiler")}
                  className={styles.option}
                >
                  Спойлер эффект <span style={style(1, "isSpoiler")}>✓</span>
                </span>
              ) : null}
              <div
                onClick={changeSendObj}
                className={styles.add_telegram_button}
              >
                <img
                  src={require("../../../../Images/plus-square_variant.png")}
                  alt=""
                />
                <span>Добавить кнопку</span>
              </div>
              <div className={styles.added_buttons}>
                {sendObj.buttons.map((el, index) => {
                  const changeText = (e, index, type) => {
                    setSendObj((state) => ({
                      ...state,
                      buttons: state.buttons.map((children, num) => {
                        if (index === num && type === "name")
                          children[0].text = e.target.value;
                        else if (index === num && type === "url")
                          children[0].url = e.target.value;
                        return children;
                      }),
                    }));
                  };
                  return (
                    <div key={index}>
                      <input
                        type="text"
                        placeholder="URL адрес кнопки"
                        value={el[0].url}
                        onChange={(e) => changeText(e, index, "url")}
                      />
                      <div className={styles.added_button}>
                        <span>↗</span>
                        <span
                          onClick={() =>
                            setSendObj((state) => ({
                              ...state,
                              buttons: state.buttons.filter(
                                (_, num) => num !== index
                              ),
                            }))
                          }
                        >
                          ✕
                        </span>
                        <input
                          placeholder="Название кнопки"
                          onChange={(e) => changeText(e, index, "name")}
                          type="text"
                          value={el[0].text}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={styles.sender_div}>
              {(sendObj.text.length && sendObj.text !== "Иван") ||
              photoState.photoForSend ? (
                <button
                  className={styles.send_button}
                  onClick={sendDataToBackEnd}
                >
                  {onLoad ? <div className={styles.loading}></div> : null}
                  Отправить пост в телеграм
                </button>
              ) : null}
              <div>Количество пользователей: {senderCount}</div>
            </div>
          </section>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default TelegramInterface;
