import React, { useCallback, useEffect, useState } from "react";
import styles from "./form.module.css";
import { useNavigate } from "react-router-dom";
import { form } from "../../Utils/utils";

const Form = ({ setBlockQuestions, blockQuestions }) => {
  const [choosedInputs, setChoosedInput] = useState([]);
  const [dataTypes, setDataTypes] = useState(form);

  const navigate = useNavigate();

  useEffect(() => {
    if (choosedInputs.length) {
      const array = structuredClone(blockQuestions);
      const arrayInputs = structuredClone(choosedInputs);
      for (let i in array) {
        for (let j in array[i].questions) {
          if (
            array[i].questions[j].type == "questionsQuizOne" ||
            array[i].questions[j].type == "questionsQuizMany" ||
            array[i].questions[j].type == "questionsQuizFreeText"
          ) {
            dataTypes.map((el) => {
              delete array[i].questions[j][el.value];
            });
            arrayInputs.map((el) => {
              array[i].questions[j][el.value] = "";
            });
          }
        }
      }
      setBlockQuestions(array);
    }
  }, [choosedInputs]);

  const setInput = useCallback(
    (key, value) => {
      if (key == "+")
        setChoosedInput((prev) => [...prev, { value: value, type: "" }]);
      else setChoosedInput((prev) => prev.filter((el) => el.value != value));
    },
    [dataTypes, choosedInputs]
  );

  return (
    <div>
      <span className={styles.back_button} onClick={() => navigate(-1)}>
        ←
      </span>
      <h2 className={styles.title}>
        В типах Ваших вопросов найден Квиз, пожалуйста настройте форму для
        идентификации победителей!
      </h2>

      <div className={styles.main_form_block}>
        {dataTypes.map((type, index) => {
          let check = false;
          if (choosedInputs.find((el) => el.value == type.value)) check = true;
          return (
            <div
              onClick={() => {
                if (choosedInputs.find((el) => el.value == type.value))
                  setInput("-", type.value);
                else setInput("+", type.value);
              }}
              style={{ opacity: check ? "1" : "0.3" }}
              key={index}
            >
              {type.value}
              <span
                style={{
                  fontSize: !check ? "5px" : "",
                  transform: !check ? "translateX(100px)" : "translateX(-10px)",
                }}
              >
                ✓
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Form;
