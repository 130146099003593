import { Route, Routes, useNavigate } from "react-router-dom";
import styles from "./users.module.css";
import { useCallback, useEffect, useRef, useState } from "react";
import { getAllUsers, getUserOnSearch, getUserTariff } from "../../../DAL/dal";
import { ChangeTariff } from "../HtmlElements/Elements";
import { useSelector } from "react-redux";
import CurrentUser from "./CurrentUser";

const Users = ({ editMode, setEditMode, height }) => {
  const navigate = useNavigate();
  const { role } = useSelector((state) => state.user);
  const [users, setUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [currentTariff, setCurrentTariff] = useState("");
  const [emailForSearch, setEmailForSearch] = useState("");
  const [usersLimit, setUsersLimit] = useState(6);
  const [totalUsers, setTotalUsers] = useState(0); // максимальное количество пользователей

  const ref = useRef();

  const changeTariffFunction = (e, user) => {
    setCurrentUser(user);
    setCurrentTariff(e.target.innerText);
    setEditMode(true);
  };

  const searchUser = () => {
    if (!emailForSearch) return;
    getUserOnSearch(emailForSearch).then((r) => {
      if (r)
        getUserTariff(r.id).then((res) => {
          r.tariff = res;
          setUsers([r]);
        });
      else setUsers([{ email: "Пользователь не найден" }]);
    });
  };

  useEffect(() => {
    if (role != "admin") navigate(-1);
  }, [role]);

  const getUsers = useCallback(() => {
    getAllUsers(usersLimit).then((r) => {
      setTotalUsers(r.count);
      let count = 0;
      const middleWare = r.users;
      const compileUsersAndTariffs = () => {
        getUserTariff(r.users[count].id).then((res) => {
          if (middleWare[count].id == res.user_id)
            middleWare[count].tariff = res;
          count++;
          if (count < r.users.length) compileUsersAndTariffs();
          else setUsers(middleWare);
        });
      };
      compileUsersAndTariffs();
    });
  }, [usersLimit]);

  useEffect(() => {
    getUsers();
  }, [usersLimit]);

  return (
    <>
      {editMode ? (
        <ChangeTariff
          user={currentUser}
          tariff={currentTariff}
          setEditMode={setEditMode}
          user_id={currentUser.id}
        />
      ) : (
        <Routes>
          <Route
            path="/"
            element={
              <section className={styles.main_users}>
                <div className={styles.comeback} onClick={() => navigate(-1)}>
                  ←
                </div>
                <h1 className={styles.users_title}>Пользователи</h1>
                <div className={styles.search_users}>
                  <input
                    placeholder="Email пользователя"
                    onBlur={() => {
                      if (!emailForSearch) {
                        getUsers();
                      }
                    }}
                    type="text"
                    value={emailForSearch}
                    onChange={(e) => setEmailForSearch(e.target.value)}
                  />
                  <button onClick={searchUser}>Найти</button>
                </div>
                <div
                  onScroll={() => {
                    if (
                      ref.current.scrollHeight -
                        ref.current.offsetHeight -
                        ref.current.scrollTop <=
                        5 &&
                      totalUsers > users.length + 1
                    ) {
                      setUsersLimit((prev) => prev + 6);
                    }
                  }}
                  ref={ref}
                  style={{ height: height * 0.55 }}
                  className={styles.users_container}
                >
                  {users.map((user, index) => {
                    return (
                      <div className={styles.user} key={index}>
                        <div className={styles.one_user}>
                          <h2>{user.name}</h2>
                          <span>{user.email}</span>
                          <div>
                            <span>{user.role}</span>
                            <img
                              onClick={() => navigate(`current/${user.id}`)}
                              className={styles.edit_image}
                              src={require("../../../Images/Button.png")}
                              alt=""
                            />
                          </div>
                        </div>
                        <div className={styles.tariffs}>
                          <span>Тарифы:</span>
                          <span
                            onClick={(e) => changeTariffFunction(e, user)}
                            style={
                              user?.tariff?.tariff_name == "base"
                                ? {
                                    background: "white",
                                    color: "black",
                                    fontWeight: "700",
                                  }
                                : {}
                            }
                          >
                            base
                          </span>
                          <span
                            onClick={(e) => changeTariffFunction(e, user)}
                            style={
                              user?.tariff?.tariff_name == "fullyear"
                                ? {
                                    background: "white",
                                    color: "black",
                                    fontWeight: "700",
                                  }
                                : {}
                            }
                          >
                            full year
                          </span>
                          <span
                            onClick={(e) => changeTariffFunction(e, user)}
                            style={
                              !user?.tariff?.tariff_name
                                ? {
                                    background: "white",
                                    color: "black",
                                    fontWeight: "700",
                                  }
                                : {}
                            }
                          >
                            отсутствует
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </section>
            }
          />
          <Route path="current/:id" element={<CurrentUser />} />
        </Routes>
      )}
    </>
  );
};

export default Users;
