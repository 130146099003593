import React, { useEffect, useRef, useState } from "react";
import styles from "./quiz.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addFreeAnswerQuizAction,
  setBetAction,
} from "../../Redux/AnswersReducer";
import { setQuizDataAction } from "../../Redux/QuestionsReducer";
import { AlertModal } from "../AdminPage/HtmlElements/Elements";
import { URL } from "../../DAL/dal";
import { app } from "../../App";

const QuizTFree = ({
  setMarginForTelegramApp,
  currentBlock,
  editMode,
  setEditMode,
  setEditModeType,
  editModeType,
  blockNameColor,
  buttonsColor,
  variantActive,
  questionNameColor,
  currentGlobalQuestionsIndex,
  betQuiz,
  textAreaState, setTextAreaState
}) => {

  const { answers } = useSelector((state) => state.answers);
  const dispatch = useDispatch();
  const [bet, setBet] = useState(false); // ставка на вопрос
  const ref = useRef();
  const areaEl = useRef();

  useEffect(() => {
    if (answers[currentGlobalQuestionsIndex].bet) setBet(true);
    else setBet(false);
  }, [currentGlobalQuestionsIndex]);

  useEffect(() => {
    if (!window.localStorage.getItem("isFirstQuiz")) {
      setEditModeType("quiz");
      setEditMode(true);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (areaEl?.current?.scrollHeight > areaEl?.current?.clientHeight) {
        setTextAreaState((state) => ({
          ...state,
          rowsCount: Math.ceil(
            areaEl?.current?.scrollHeight / areaEl?.current?.clientHeight
          ),
        }));
      }
    }, 200);
  }, []);

  useEffect(() => {
    setTextAreaState((state) => ({
      ...state,
      content: currentBlock.list[0].answer,
    }));
  }, [areaEl]);

  const typeAnswer = (e) => {
    dispatch(
      addFreeAnswerQuizAction(e.target.value, currentGlobalQuestionsIndex)
    );
  };

  const [formInputs, setFormInputs] = useState([]);
  const [isForm, setIsForm] = useState(false);
  const [invocation, setInvocation] = useState("");
  const [welcome, setWelcome] = useState(false); // для того чтобы отрисовать заголовок один раз и не менять его впоследствии
  const [checkPrivatePolice, setCheckPrivatePolice] = useState(true);

  useEffect(() => {
    // будем использовать тот же ключ FI в local storage что и для вопросов спикеру
    const FI = window.localStorage.getItem("FI");
    const arr = [
      { type: "", value: "" },
      { type: "", value: "" },
      { type: "", value: "" },
      { type: "", value: "" },
      { type: "", value: "" },
      { type: "", value: "" },
      { type: "", value: "" },
      { type: "", value: "" },
    ];
    for (let key in currentBlock) {
      switch (key) {
        case "Имя":
          arr[0].type = key;
          break;
        case "Фамилия":
          arr[1].type = key;
          break;
        case "Отчество":
          arr[2].type = key;
          break;
        case "Телефон":
          arr[3].type = key;
          break;
        case "Почта":
          arr[4].type = key;
          break;
        case "Город":
          arr[5].type = key;
          break;
        case "Команда":
          arr[6].type = key;
          break;
        case "Компания":
          arr[7].type = key;
          break;
      }
    }
    if (!FI) {
      setFormInputs(arr);
    } else {
      // если такой ключ существует в localStorage
      const objFI = FI.split(",");
      for (let i in arr) {
        if (arr[i].type && !objFI[i]) {
          setFormInputs((prev) => [...prev, { type: arr[i].type, value: "" }]);
        } else {
          setFormInputs((prev) => [...prev, { type: "", value: objFI[i] }]);
        }
      }
    }
  }, []);

  const compileToLocalStorage = () => {
    const CheckSpaces = (str) => str.trim() !== "";
    for (let i in formInputs) {
      if (formInputs[i].type && !formInputs[i].value)
        return alert("Заполните все поля!");
      else if (formInputs[i].value && !CheckSpaces(formInputs[i].value))
        return alert("Заполните все поля не только пробелами!");
    }
    const arr = ["", "", "", "", "", "", "", ""]; // функция, компелирующая заполненный массив для localStorage
    for (let i in formInputs) {
      arr[i] = formInputs[i].value;
    }
    window.localStorage.setItem("FI", arr);
    const newArr = arr.map((el, index) => {
      switch (index) {
        case 0:
          el = { type: "Имя", value: el };
          break;
        case 1:
          el = { type: "Фамилия", value: el };
          break;
        case 2:
          el = { type: "Отчество", value: el };
          break;
        case 3:
          el = { type: "Телефон", value: el };
          break;
        case 4:
          el = { type: "Почта", value: el };
          break;
        case 5:
          el = { type: "Город", value: el };
          break;
        case 6:
          el = { type: "Команда", value: el };
          break;
        case 7:
          el = { type: "Компания", value: el };
          break;
      }
      return el;
    });
    dispatch(setQuizDataAction(newArr));
    setIsForm(false);
  };

  useEffect(() => {
    if (!welcome) {
      const arr = [];
      setInvocation("");
      for (let i in formInputs) {
        if (formInputs[i].type) arr.push(formInputs[i].type);
      }
      if (arr.length) {
        setIsForm(true);
        setInvocation(
          formInputs[0]?.value
            ? `${formInputs[0].value}, данные о Вас найдены частично, пожалуйста заполните остальные поля для прохождения квиза, чтобы мы смогли идентифицировать Вас в случае победы`
            : "Пожалуйста заполните форму для прохождения квиза, чтобы мы смогли идентифицировать Вас в случае победы"
        );
        setWelcome(true);
      } else if (formInputs.length) {
        compileToLocalStorage();
      }
    }
    // если мы находим несоответствие данных в localStorage и тем что требуется, то отрисовываем форму с недостающими данными
  }, [formInputs]);

  // console.log(currentBlock);

  return (
    <>
      {editMode ? (
        <AlertModal
          info={
            "Здравствуйте, данный вопрос относится к разряду викторина. Способ ответа может быть как выбор из предложенных вариантов, либо свободный ответ. Для Вашей идентификации система запросит кое какие данные. Желаем Вам удачи!"
          }
          setEditMode={setEditMode}
          setEditModeType={setEditModeType}
          ancillary={true}
          editModeType={editModeType}
        />
      ) : (
        <>
          {isForm ? ( // проверяем нужна ли форма
            <div className={styles.quiz_form}>
              <h1
                style={{ color: blockNameColor }}
                className={styles.invocation}
              >
                {invocation}
              </h1>
              <div className={styles.form_container}>
                {formInputs.map((el, index) => {
                  if (el.type)
                    return (
                      <input
                        key={index}
                        placeholder={el.type}
                        value={el.value}
                        onChange={(e) => {
                          setFormInputs((prev) =>
                            prev.map((el, count) =>
                              count === index
                                ? { ...el, value: e.target.value }
                                : el
                            )
                          );
                        }}
                      />
                    );
                })}
                <div className={styles.private_police}>
                  <div
                    onClick={() => setCheckPrivatePolice(!checkPrivatePolice)}
                  >
                    <div>
                      <span
                        style={{
                          transform: checkPrivatePolice
                            ? ""
                            : "translateX(-25px)",
                        }}
                      >
                        ✓
                      </span>
                    </div>
                    <span>Согласие на обработку персональных данных</span>
                  </div>
                  <a
                    href={URL + `static_information/private_police.pdf`}
                    target="_blank"
                  >
                    Политика конфеденциальности
                  </a>
                </div>
                <button
                  className={styles.save_button}
                  onClick={() => {
                    if (!checkPrivatePolice)
                      return alert(
                        "Подтвердите согласие на обработку персональных данных"
                      );
                    compileToLocalStorage();
                  }}
                  style={{
                    background: buttonsColor[0],
                    color: buttonsColor[1],
                    cursor: "pointer",
                  }}
                >
                  Сохранить
                </button>
              </div>
            </div>
          ) : (
            <div
              ref={ref}
              style={{
                // height: app.platform === "ios" ? textAreaState.prevHeight : "",
                marginBottom: "auto",
              }}
              className={styles.head_question_container_t}
            >
              <h1 className={styles.head} style={{ color: blockNameColor }}>
                Вопрос викторина
              </h1>
              <h2
                className={styles.head_h3}
                style={{ color: questionNameColor }}
              >
                {currentBlock.blockName}
              </h2>
              {betQuiz && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <section
                    onClick={() => {
                      dispatch(setBetAction(currentGlobalQuestionsIndex, !bet));
                      setBet(!bet);
                    }}
                    style={{ background: bet ? "rgb(76, 250, 76)" : "" }}
                    className={styles.bet_button}
                  >
                    <section style={{ marginLeft: bet ? "50%" : "" }}></section>
                  </section>
                  <span className={styles.bet_description}>
                    Ставка на этот вопрос
                  </span>
                </div>
              )}
              <textarea
                ref={areaEl}
                rows={textAreaState.rowsCount}
                onFocus={() => {
                  if (app.platform === "ios") {
                    setMarginForTelegramApp("40%");
                    // setTextAreaState((state) => ({
                    //   ...state,
                    //   prevHeight: "40vh",
                    // }));
                  }
                }}
                onBlur={() => {
                  if (app.platform === "ios") {
                    setMarginForTelegramApp("");
                    // setTextAreaState((state) => ({
                    //   ...state,
                    //   prevHeight: "90vh",
                    // }));
                  }
                  setTimeout(() => {
                    dispatch(
                      addFreeAnswerQuizAction(
                        textAreaState.content,
                        currentGlobalQuestionsIndex
                      )
                    );
                  }, 100);
                }}
                className={styles.free_text_area}
                type="text"
                // value={currentBlock.list[0].answer}
                value={textAreaState.content}
                placeholder="Ваш ответ"
                onChange={(e) => {
                  if (textAreaState.scrollHeight == 0)
                    setTextAreaState((state) => ({
                      ...state,
                      scrollHeight: e.target.scrollHeight,
                    }));
                  if (
                    e.target.scrollHeight > textAreaState.scrollHeight &&
                    textAreaState.scrollHeight > 0
                  ) {
                    setTextAreaState((state) => ({
                      ...state,
                      scrollHeight: e.target.scrollHeight,
                      rowsCount: (state.rowsCount += 1),
                    }));
                  }
                  // typeAnswer(e);
                  setTextAreaState((state) => ({
                    ...state,
                    content: e.target.value,
                  }));
                }}
                style={{
                  borderBottom: "1px solid white",
                  color: blockNameColor,
                }}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default QuizTFree;
