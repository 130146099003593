import React from "react";
import { useEffect, useState } from "react";
import styles from "../../Styles.module.css";

const VariantComponent = ({
  index,
  blockQuestions,
  setBlockQuestions,
  questionIndex,
  listIndex,
}) => {
  const onChangeVariant = (e) => {
    if (setBlockQuestions == null) return;
    setBlockQuestions((prev) =>
      prev.map((el, count) => {
        return count === index
          ? {
              ...el,
              questions: el.questions.map((q, number) => {
                return number === questionIndex
                  ? {
                      ...q,
                      list: q.list.map((li, num) => {
                        return num === listIndex
                          ? { ...li, name: e.target.value }
                          : li;
                      }),
                    }
                  : q;
              }),
            }
          : el;
      })
    );
  };

  return (
    <div>
      <div style={{ position: "relative" }}>
        <input
          className={styles.variant_input}
          type="text"
          placeholder={
            blockQuestions[index].questions[questionIndex].list[listIndex]
              .isCorrect
              ? `Верный ответ ${listIndex + 1}`
              : `Вариант ответа ${listIndex + 1}`
          }
          value={
            blockQuestions[index].questions[questionIndex].list[listIndex].name
          }
          onChange={onChangeVariant}
        />
        {listIndex > 1 && (
          <img
            onClick={() => {
              const arr = structuredClone(blockQuestions);
              const newArr = arr.filter((el, i) => {
                if (i === index) {
                  return (el.questions[questionIndex].list = arr[
                    index
                  ].questions[questionIndex].list.filter(
                    (l, count) => count != listIndex
                  ));
                } else {
                  return el;
                }
              });
              if (setBlockQuestions == null) return;
              setBlockQuestions(newArr);
            }}
            className={styles.trash_variant}
            src={require("../../../../../Images/trash.png")}
            alt=""
          />
        )}
      </div>
    </div>
  );
};

export default VariantComponent;
