import axios from "axios";
import inMemoryJWTService from '../inMemoryJWT/inMemoryJWT'
const shortid = require("shortid");


// export const URL = "http://localhost:5000/";
// export const URL = "https://psql-congress.pep-server.ru/";
export const URL = "https://api-congress.pointreg.ru/";

const instance = axios.create({
  baseURL: URL,
  withCredentials: true
});

// instance.interceptors.request.use(config => {
//   config.headers.Authorization = window.localStorage.getItem('token')
//   return config
// })
instance.interceptors.request.use(
  (config) => {
    const accessToken = inMemoryJWTService.getToken();
    if (accessToken) config.headers["Authorization"] = `Bearer ${accessToken}`;
    return config;
  },
  (error) => Promise.reject(error)
);


//_________запросы пользователя_________________________
export const getUser = () => {
  return instance.get('api/user').then(response => response) //
}
export const getAllUsers = (usersLimit) => {
  return instance.get(`api/users?usersLimit=${usersLimit}`).then(response => response.data) //
}
export const getUserOnSearch = (email) => {
  return instance.get(`api/user-on-search?email=${email}`).then(response => response.data) //
}
export const getUserWithOutToken = (id) => {
  return instance.get(`api/user/${id}`).then(response => response) //
}
export const singUp = (name, password, email) => {
  return instance.post('api/user', { name, password, email }).then(response => response) //
}
export const loginUser = (password, email) => {
  return instance.post('api/user-login', { password, email }).then(response => response) //
}
export const refresh = () => {
  return instance.post('api/refresh').then(response => response) //
}
export const logOut = () => {
  return instance.post('api/logout').then(response => response) //
}
export const forgetPass = (email) => {
  return instance.get(`api/forget-pass?email=${email}`).then(response => response) //
}
export const checkConponent = (mod_id) => {
  return instance.get(`api/check-component?mod_id=${mod_id}`).then(response => response) //
}
export const changeUserPassword = (password, mod_id) => {
  return instance.post(`api/change-password`, { password, mod_id }).then(response => response) //
}
//______________________________________
// ____________________тарифы______________________

export const getUserTariff = (id) => {
  return instance.get(`api/user-tariff/${id}`).then(response => response.data) //
}
export const getTariffOfCurrentUser = () => {
  return instance.get(`api/current-tariff`).then(response => response.data) //
}
export const minusOneSession = () => {
  return instance.get(`api/minus-session`).then(response => response.data) //
}
export const setTariff = (user_id, tariff) => {
  return instance.get(`api/set-tariff?user_id=${user_id}&tariff=${tariff}`).then(response => response.data) //
}
export const addSessionsOnTariff = (user_id, sessionsCount) => {
  return instance.get(`api/update-cout-sessions?user_id=${user_id}&sessionsCount=${sessionsCount}`).then(response => response.data) //
}
//_________________________________________________

//_________________модерация________________________

export const createNewAskOrDelete = (sessionId, key) => {
  return instance.post(`api/create-or-delete`, { sessionId, key }).then(response => response.data) //
}
export const findAskForSession = (sessionId) => {
  return instance.get(`api/find-ask?sessionId=${sessionId}`).then(response => response.data) //
}
export const moderation = (seed, id) => {
  return instance.get(`api/moderation?seed=${seed}&id=${id}`).then(response => response.data) //
}
export const getAllQuestions = (id) => {
  return instance.get(`api/questions/${id}`).then(response => response.data) //
}
export const deleteAllQuestions = (id) => {
  return instance.delete(`api/questions/${id}`).then(response => response.data) //
}
export const deleteQuestion = (id, key) => {
  return instance.delete(`api/questions?id=${id}&key=${key}`).then(response => response.data) //
}
export const setIsAnswered = (id, key) => {
  return instance.get(`api/questions-update?id=${id}&key=${key}`).then(response => response.data) //
}
export const getModerationState = (id) => {
  return instance.get(`api/moderation/${id}`).then(response => response.data) //
}
export const getCurrentModerator = (id) => {
  return instance.get(`api/get-moderator/${id}`).then(response => response.data) //
}
export const changeModerationRole = (id) => {
  return instance.get(`api/moderation-change-role/${id}`).then(response => response.data) //
}
export const getQuestionsForModeration = (id) => {
  return instance.get(`api/get-questions-for-moderation/${id}`).then(response => response.data) //
}
export const addQuestionForModeration = (id, question) => {
  return instance.get(`api/add-question-for-moderation?id=${id}&question=${question}`).then(response => response.data) //
}
export const deleteQuestionsForModeration = (id, index) => {
  return instance.get(`api/delete-questions-for-moderation?id=${id}&index=${index}`).then(response => response.data) //
}
export const getOrUpdateCurrentScreeen = (id, oldScreen = '') => {
  return instance.get(`api/get-screen?id=${id}&oldScreen=${oldScreen}`).then(response => response.data) //
}


//__________________________________________________

//________________запросы сессий_______________________

export const changeFreeAnswerStatus = (req_id, req_key, req_name, sessionId, type) => {
  return instance.post('api/update-free-answer-status', { req_id, req_key, req_name, sessionId, type }).then(response => response.data) //
}
export const createNewSession = (colors, backgroundImage, questions, sessionName, isAsk) => {
  return instance.post('api/session', { colors, backgroundImage, questions, sessionName, isAsk }).then(response => response) //
}
export const updateQuestionsDate = (questionsData, id, idKey = shortid.generate()) => {
  return instance.post('api/session-update-case', { questionsData, id, idKey }).then(response => response) //
}
export const updateSessionName = (name, id) => {
  return instance.post('api/session-update-name', { name, id }).then(response => response) //
}
export const getAllUserSessions = (limit) => {
  return instance.get(`api/session?limit=${limit}`).then(response => response) //
}
export const searchSessions = (str, role) => {
  return instance.get(`api/search-sessions?str=${str}&role=${role}`).then(response => response) // поиск сессий на сервере
}
export const getAllAdminSessions = (limit) => {
  return instance.get(`api/session-admin?limit=${limit}`).then(response => response) // получаешь все сессии если ты админ
}
export const getAllPreviousImages = () => {
  return instance.get(`api/previous-images`).then(response => response.data) // получаешь все сессии если ты админ
}
export const activeSessionStatus = (id) => {
  return instance.put(`api/session-admin/${id}`).then(response => response) // активируем сессию
}
export const endedTime = (id, time) => {
  return instance.get(`api/session-set-ended-time?id=${id}&time=${time}`).then(response => response) //
}
export const getOneSession = (id) => {
  return instance.get(`api/session/${id}`).then(response => response) //
}
export const getCurrentResult = (id) => {
  return instance.get(`api/session-current-result/${id}`).then((response) => response); //
};
export const clearAllData = (id) => {
  return instance.get(`api/session-delete-results/${id}`).then((response) => response); //
};
export const clearOneData = (id) => {
  return instance.get(`api/session-delete-result/${id}`).then((response) => response); //
};
export const getAllResults = (id) => {
  return instance.get(`api/session-all-results/${id}`).then((response) => response); //
};
export const getAllQuizResult = (id) => {
  return instance.get(`api/session-all-results?id=${id}`).then((response) => response); //
};
export const deleteSession = (id) => {
  return instance.delete(`api/session/${id}`).then((response) => response); //
};
export const changeSessionColors = (colors, id) => {
  return instance.put('api/session', { colors, id }).then((response) => response); //
};
export const setSesseonDescription = (id, description) => {
  return instance.put('api/session-description', { id, description }).then((response) => response); //
};
export const addQuizBet = (bet, sessionId) => {
  return instance.get(`api/add-bet?bet=${bet}&sessionId=${sessionId}`).then((response) => response); //
};
export const addBackgroundFone = (photo, id) => {
  let formdata = new FormData();
  formdata.append("image", photo);
  return instance
    .put(`add-fone/${id}`, formdata, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((response) => response);
};

//__________Telegram__________________________________________________________________________
export const sendTelegramImage = (id, photo, text, isSpoiler, buttons, isSetName) => { // для телеграм поста
  let formdata = new FormData();
  formdata.append("image", photo);
  return instance
    .put(`api/telegram-image?id=${id}&text=${text}&isSpoiler=${isSpoiler}&buttons=${buttons}&isSetName=${isSetName}`,
      formdata, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((response) => response);
};

export const getAllTelegramContacts = (id) => {
  return instance.get(`api/telegram-contacts?id=${id}`).then((response) => response.data); //
};
export const sendNewMessage = (id, text, buttons, isSetName) => {
  return instance.get(`api/telegram-message?id=${id}&text=${text}&buttons=${buttons}&isSetName=${isSetName}`).then((response) => response.data); //
};

//_______________________________________


