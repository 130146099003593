import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { changeUserPassword, checkConponent } from "../../../DAL/dal";
import { AlertModal } from "../HtmlElements/Elements";
import styles from "../Admin.module.css";
import st from "../HtmlElements/elements.module.css";

const CangePassComponent = ({
  editMode,
  setEditMode,
  setEditModeType,
  editModeType,
  setForEditMode,
  forEditMode,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isShowPass, setIsShowPass] = useState(false);
  const [onLoad, setOnLoad] = useState(false);
  const [passwordState, setPasswordState] = useState({
    firstPass: "",
    secondPass: "",
    validFirstPass: true,
    validSecondPass: true,
  });

  const onChangeState = (e) => {
    const { name, value } = e.target;
    setPasswordState((state) => ({ ...state, [name]: value }));
    switch (name) {
      case "firstPass":
        if (value.length < 5 && value.length > 0)
          setPasswordState((state) => ({ ...state, validFirstPass: false }));
        else setPasswordState((state) => ({ ...state, validFirstPass: true }));
        break;
      case "secondPass":
        if (value.length < 5 && value.length > 0)
          setPasswordState((state) => ({ ...state, validSecondPass: false }));
        else setPasswordState((state) => ({ ...state, validSecondPass: true }));
        break;
      default:
        break;
    }
  };


  useEffect(() => {
    if (id)
      checkConponent(id).then((r) => {
        if (!r.data) {
          setForEditMode("Ссылка недействительна");
          setEditMode(true);
          navigate("/admin");
        }
      });
  }, [id]);

  const sendNewPassword = () => {
    const { firstPass, secondPass } = passwordState;
    if (firstPass === secondPass) {
      setOnLoad(true);
      changeUserPassword(firstPass, id)
        .then((r) => {
          setEditModeType("passIsChanged");
          setEditMode(true);
          setOnLoad(false);
        })
        .catch((err) => {
          setOnLoad(false);
          alert(err);
        });
    } else alert("Что то не так");
  };
  const changeIsShow = () => {
    setIsShowPass(!isShowPass);
  };

  return (
    <>
      {!editMode ? (
        <div
          className={styles.loginAndSingContainer}
          style={{ margin: "0 0 auto 0", width: "100%" }}
        >
          <div className={styles.inputDiv}>
            {!isShowPass ? (
              <img
                style={{ top: "7px" }}
                onClick={changeIsShow}
                src={require("../../../Images/eye.png")}
                alt=""
              />
            ) : (
              <img
                style={{ top: "0" }}
                onClick={changeIsShow}
                src={require("../../../Images/eye-off.png")}
                alt=""
              />
            )}
            <input
              style={{
                borderBottom: passwordState.validFirstPass
                  ? "1px solid rgba(255, 255, 255, 0.5)"
                  : "1px solid red",
                width: "100%",
                marginBottom: "30px",
              }}
              name="firstPass"
              type={!isShowPass ? "password" : "text"}
              placeholder="Введите пароль"
              onChange={onChangeState}
              value={passwordState.firstPass}
            />

            <input
              style={{
                borderBottom: passwordState.validSecondPass
                  ? "1px solid rgba(255, 255, 255, 0.5)"
                  : "1px solid red",
                width: "100%",
              }}
              name="secondPass"
              type={!isShowPass ? "password" : "text"}
              placeholder="Повторите пароль"
              onChange={onChangeState}
              value={passwordState.secondPass}
            />
          </div>
          <button onClick={sendNewPassword}>
            {onLoad ? <div className={st.loading}></div> : null}
            Обновить пароль
          </button>
        </div>
      ) : (
        <AlertModal
          info={"Пароль успешно изменен"}
          setEditMode={setEditMode}
          setEditModeType={setEditModeType}
          editModeType={editModeType}
        />
      )}
    </>
  );
};

export default CangePassComponent;
