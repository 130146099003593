import { useDispatch, useSelector } from "react-redux";
import "./App.css";
import { useCallback, useEffect, useMemo, useState } from "react";
import { initialStateAction, setSessionIdAction } from "./Redux/QuestionsReducer";
import { modalTypes } from "./types";
import FreeAnswer from "./components/FreeAnswer/FreeAnswer";
import QuestionsChoiceOfOptionsMany from "./components/QuestionsChoiceOfOptionsMany/QuestionsChoiceOfOptionsMany";
import QuestionsChoiceOfOptionsOne from "./components/QuestionsChoiceOfOptionsOne/QuestionsChoiceOfOptionsOne";
import QuestionsOnScale from "./components/QuestionsOnScale/QuestionsOnScale";
import { addFreeAnswerQuizAction, initAnswerStateAction } from "./Redux/AnswersReducer";
import { SenderComponent } from "./components/FinalComponents/SenderComponent";
import { compileResultFunction, throwConfetti } from "./components/Utils/utils";
import WelcomeComponent from "./components/WelcomeComponent/WelcomeComponent";
import { Route, Routes, useNavigate } from "react-router-dom";
import MainResults from "./components/MainResults/MainResults";
import socketIO from "socket.io-client";
import CasesPanel from "./components/CasesPanel/CasesPanel";
import { URL, getAllQuestions, getOneSession, getOrUpdateCurrentScreeen, getTariffOfCurrentUser, getUser, refresh } from "./DAL/dal";
import { TestDiagramm } from "./components/TestDiagramm/TestDiagramm";
import AdminPage from "./components/AdminPage/AdminPage";
import admin_background from './Images/2-2.png'
import AskSpeakerComponent from "./components/Ask_speaker/AskSpeakerComponent";
import ForSetSessionId from "./components/WelcomeComponent/ForSetSessionId";
import logo from './Images/Main Logo.png'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from "react-dnd";
import QuizOneOrMany from "./components/QuizClient/QuizOneOrMany";
import { exitAction, setUserDataAction } from "./Redux/UserReducer";
import QuizTFree from "./components/QuizClient/QuizTFree";
import inMemoryJWTService from './inMemoryJWT/inMemoryJWT'
import config from './components/AdminPage/Authorization/config'


export const app = window.Telegram.WebApp // для полного окна в телеграмме
app.expand()
// app.isClosingConfirmationEnabled = true;
// let socket = socketIO.connect(URL.slice(0, -1));
// let socket = socketIO.connect("psql-congress.pep-server.ru", {
//   transports: ["polling"],
// });
let socket = socketIO.connect("api-congress.pointreg.ru", {
  transports: ["polling"],
});

function App() {


  useEffect(() => { app.ready() }, [])

  const [height, setHeight] = useState(window.innerHeight)
  const [width, setWidth] = useState(window.innerWidth)
  useEffect(() => {
    const handleResize = (event) => {
      setHeight(event.target.innerHeight)
      setWidth(event.target.innerWidth)
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // return <>
  //   <div style={{ width: width, height: height, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  //     <h2 style={{ textAlign: 'center' }}>Приложение находится на техническом обслуживании</h2>
  //   </div>
  // </>


  useEffect(() => {
    if (!window.location.hash) navigate('/admin') // если загружают голый url то редиректит на админку
  }, [])

  const dispatch = useDispatch();
  socket.on("disconnect", () => {
    console.log("disconnect");
  });

  useEffect(() => { // для того чтобы автоматически выходил со всех вкладок в браузере
    const handlePersistedLogout = (event) => {
      if (event.key === config.LOGOUT_STORAGE_KEY) {
        inMemoryJWTService.deleteToken();
        dispatch(exitAction());
      }
    };
    window.addEventListener("storage", handlePersistedLogout);
    return () => window.removeEventListener("storage", handlePersistedLogout);
  }, []);

  //____________________________________________________ состояние для компоненты переключения кейсов
  const [cases, setCases] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  //______________________________________________________

  const [resolveNextBlock, setResolveNextBlock] = useState(false) // отвечает за плавное исчезновение и появление блоков вопросов

  const setNextOrPrevQuestion = (simbol) => new Promise((resolve, reject) => {
    return setTimeout(() => resolve(setCurrentGlobalQuestionsIndex(prev => simbol ? prev + 1 : prev - 1)), 300) // функция, отвечающая за плавное переключение вопросов
  })

  const [marginForTelegramApp, setMarginForTelegramApp] = useState('0')
  const [initApp, setInitApp] = useState(false)
  const [currentGlobalQuestionsIndex, setCurrentGlobalQuestionsIndex] =
    useState(0);
  const { login } = useSelector((state) => state.user);
  const { questions, backgroundImage, containerColor, buttonsColor, session_status, clientTextColor, variantActive, sessionId, blockNameColor, questionNameColor } = useSelector((state) => state.questions);
  const { dontShow, answers } = useSelector((state) => state.answers);
  const [vector, setVector] = useState("next");
  const [runToFinalComponents, setRunToFinalComponents] = useState(false);
  const [welcome, setWelcome] = useState(false);
  const [currentCaseName, setCurrentCaseName] = useState("");
  const [finalArrayLength, setFinalArrayLength] = useState(
    compileResultFunction(answers)
  );

  const [textAreaState, setTextAreaState] = useState({
    // Для управления textarea
    // Вынес эту промежуточную textarea для того чтобы можно было чекать состояние промежуточного инпута для отображения кнопки отправить до диспатча в редакс
    rowsCount: 1,
    scrollHeight: 0,
    prevHeight: "90vh",
    content: "",
  });

  const [betQuiz, setBetQuiz] = useState(false) // можно ли делать ставку в квизе
  const [currentScreen, setCurrentScreen] = useState("result"); // текущий скрин true скрин результатов
  const [reRender, setRerender] = useState(0); // система каждый раз реагирует на эту переменную для пепрерисовки приложения
  const [isAsk, setIsAsk] = useState(false) // нужно ли работать с сущностью для задавания вопросов
  const navigate = useNavigate()
  socket.on("pushIndex", (index) => { // реагируем на изменение кэйса на сервере для перерисовки приложения
    if (sessionId === index) { // только в случае если id сессии пришедший соответствует id сессии текущего клиента, то происходит перерисовка приложения. 
      const randome = Math.floor(Math.random() * 10000);
      setWelcome(false)
      setRerender(randome);
      setCurrentGlobalQuestionsIndex(0);
      setRunToFinalComponents(false)
    }
  });
  // console.log(compileResultFunction(answers))

  // проверяем наличие хотябы одного ответа для отправки результатов
  useEffect(() => {
    setFinalArrayLength(compileResultFunction(answers));
  }, [answers, reRender]);
  //

  const [isFirstRender, setIsFirstRender] = useState(true) // для того чтобы избежать двойного запроса при первом рендере
  // далее система запонимает значение и в случае нового подключения получает новые данные с сервера, теоретически это поможет избежать ответов на старые вопросы
  socket.on('connect', () => {
    if (!isFirstRender) {
      const randome = Math.floor(Math.random() * 10000);
      setRerender(randome);
    }
  })

  useEffect(() => {
    if (
      dontShow.find((index) => index == currentGlobalQuestionsIndex + 1) &&
      vector === "next"
    ) {
      setCurrentGlobalQuestionsIndex((prev) => prev + 1);
    } else if (
      dontShow.find((index) => index == currentGlobalQuestionsIndex + 1) &&
      vector === "pred"
    ) {
      setCurrentGlobalQuestionsIndex((prev) => prev - 1);
    }
  }, [currentGlobalQuestionsIndex, reRender]);

  useEffect(() => {
    if (window.location.hash.slice(2, 7) == 'admin') {
      refresh().then(r => {
        const { accessToken, accessTokenExpiration } = r.data;
        inMemoryJWTService.setToken(accessToken, accessTokenExpiration);
        getUser()
          .then((res) => {
            getTariffOfCurrentUser().then((response) => {
              // уточняем тариф у конкретного пользователя
              const { name, email, role } = res.data;
              dispatch(setUserDataAction(name, email, role, response));
              setInitApp(true)
            });
          })
          .catch((e) => {
            setInitApp(true)
            console.log(e)
          });
      }).catch(err => {
        setInitApp(true)
        console.log(err)
      })
    } else {
      dispatch(setSessionIdAction(window.location.hash.split('/')[window.location.hash.split('/').length - 1]));
    }
  }, [login])


  // получаем кэйс приложения с сервера
  useEffect(() => {
    if (sessionId) { // для пользователя '/' мы получаем этот параметр из компоненты welcome и далее на пользователе он сохраняется в глобальный стейт
      getOneSession(sessionId).then((r) => {
        const { questionsdata, backgroundimage, session_status, containercolor, variantactive, buttonscolor, clienttextcolor, globalindex, blocknamecolor, questionnamecolor, isask, bet_quiz } = r.data
        const index = Number(globalindex)
        setIsAsk(isask)
        setCases(questionsdata)
        setCurrentIndex(index)
        setIsFirstRender(false)
        setInitApp(true)
        setBetQuiz(bet_quiz)
        if (questionsdata.length) {
          dispatch(initialStateAction(questionsdata[index].questions, backgroundimage, session_status, containercolor, variantactive, buttonscolor, clienttextcolor, blocknamecolor, questionnamecolor))
          dispatch(initAnswerStateAction(questionsdata[index].questions));
          setCurrentCaseName(questionsdata[index].caseName);
        } else {
          dispatch(initialStateAction([], backgroundimage, session_status, containercolor, variantactive, buttonscolor, clienttextcolor, blocknamecolor, questionnamecolor))
        }

      }).catch(e => {
        setInitApp(true)
        console.log(e)
      }
      )
    }
  }, [reRender, sessionId]);
  //
  //_________________________________________________Область кода отвечающая за получение всех вопросов аудитории________________________

  const [questionsToLector, setQuestionsToLector] = useState([]);
  const sortFunction = useCallback((array) => {
    return array.sort(function (a, b) {
      if (a.isAnswered != b.isAnswered) {
        return a.isAnswered ? 1 : -1;
      }
      if (a.range != b.range) {
        return b.range - a.range;
      }
    })
  }, [])

  // useEffect(()=>{console.log(questionsToLector)},[questionsToLector])

  useEffect(() => {
    if (sessionId && isAsk) // получаем все промодерированные вопросы, если есть id сессии и мы понимаем что они есть isAsk
      getAllQuestions(sessionId).then((r) => {
        setQuestionsToLector(sortFunction(r));
      });
  }, [sessionId, isAsk, reRender])

  const socketId = useMemo(() => Math.floor(Math.random() * 1000000), []) // чтобы не сохранять ключ randeUp в storage для всех пользователей

  useEffect(() => {
    if (sessionId) {
      socket.on("updateRange", (data) => {
        const { key, x, y, currentId, my_id } = data;
        if (sessionId === currentId) {
          setQuestionsToLector((prev) =>
            prev.map((el) => {
              if (el.key === key) el.range += 1;
              return el;
            })
              .sort(function (a, b) {
                return b.range - a.range;
              })
          );
          if (my_id === socketId) {// проверка сокета
            const storage = window.localStorage.getItem("rangeUp");
            if (!storage) window.localStorage.setItem("rangeUp", key);
            else {
              const arr = storage.split(",");
              arr.push(key);
              window.localStorage.setItem("rangeUp", arr);
            }
          }
          throwConfetti((x / 100).toFixed(2), (y / 100).toFixed(2));
        }
      });
      socket.on("setUpdateQuestions", (data) => {
        if (data.id == sessionId)
          setQuestionsToLector(sortFunction(data.r));
      });
      socket.on("addQuestion", (data) => {
        if (data.id == sessionId)
          setQuestionsToLector((prev) =>
            sortFunction([...prev, data.question])
          );
      });

    }
  }, [sessionId, socket])

  useEffect(() => {
    if (isAsk) {
      getOrUpdateCurrentScreeen(sessionId).then((r) => {
        setCurrentScreen(r);
      });
    }
    // получаем скрин при первой инициализации
  }, [isAsk])

  //_________________________________________________Область кода отвечающая за получение всех вопросов аудитории________________________
  const [editMode, setEditMode] = useState(false); // режим когда все скрывается и окно становится 100vw, а в центре редактирование
  const [editModeType, setEditModeType] = useState(""); // что загружаем в edit mode


  if (session_status == 'не активна') {
    return
  }

  const checkForSendButton = () => { // все вопросы должны быть отвечены, иначе система не допустит до отправки результатов
    let check = false
    for (let i in answers) {
      if (answers[i].list) {
        if (answers[i].list.find(li => { if (li.isChecked && li.answer == undefined) return li.isChecked }) || textAreaState.content
          // || answers[i].list.find(li => { if (li.isChecked == undefined && li.answer) return li.answer })
        )
          continue;
        else return check
      }
      else {
        if (answers[i].range.choose) continue;
        else return check
      }
    }
    check = true
    return check
  }



  return (
    <section
      style={{
        height: app.platform === 'ios' ? app.viewportHeight
          : height,
        background: window.location.hash.slice(0, 7) == '#/admin' && 'linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0))'
      }} // для определенной стилизации фона
      className="App">
      {window.location.hash.slice(0, 7) == '#/admin' ? <img style={{ height: height }} src={admin_background} className="adminBackground" /> : <img style={{
        height: app.platform === 'ios' ? app.viewportHeight
          : height,
      }} src={backgroundImage}
        className="appBackground"
      />}
      {!initApp ? <img className="start_logo" src={logo} /> :
        <Routes>
          <Route
            path="cases/:id"
            element={
              <>
                <CasesPanel
                  height={height}
                  setCurrentScreen={setCurrentScreen}
                  currentScreen={currentScreen}
                  isAsk={isAsk}
                  sortFunction={sortFunction}
                  questionsToLector={questionsToLector}
                  setQuestionsToLector={setQuestionsToLector}
                  currentIndex={currentIndex}
                  setCurrentIndex={setCurrentIndex}
                  cases={cases}
                  setCases={setCases}
                  reRender={reRender}
                  socket={socket} currentGlobalQuestionsIndex={currentGlobalQuestionsIndex}
                />
              </>
            }
          />
          <Route
            path="test"
            element={<TestDiagramm />}
          />
          <Route
            path="result/:id"
            element={
              <>
                <MainResults
                  setCurrentScreen={setCurrentScreen}
                  currentScreen={currentScreen}
                  sortFunction={sortFunction}
                  questions={questions}
                  questionsToLector={questionsToLector}
                  setQuestionsToLector={setQuestionsToLector}
                  clientTextColor={clientTextColor}
                  questionNameColor={questionNameColor}
                  blockNameColor={blockNameColor}
                  containerColor={containerColor}
                  reRender={reRender}
                  socket={socket}
                  cases={cases}
                  sessionId={sessionId}
                /></>
            }
          />
          <Route
            path="admin/*"
            element={
              <DndProvider backend={HTML5Backend}>
                <AdminPage setEditModeType={setEditModeType}
                  height={height}
                  editModeType={editModeType}
                  editMode={editMode}
                  setEditMode={setEditMode}
                  reRender={reRender}
                  setRerender={setRerender} />
              </DndProvider>
            }
          />
          <Route
            path="ask-speaker/:id"
            element={
              <AskSpeakerComponent
                socketId={socketId}
                height={height}
                width={width}
                editMode={editMode}
                setEditMode={setEditMode}
                setEditModeType={setEditModeType}
                questionsToLector={questionsToLector}
                setQuestionsToLector={setQuestionsToLector}
                socket={socket}
                questionNameColor={questionNameColor}
                buttonsColor={buttonsColor}
                clientTextColor={clientTextColor}
                containerColor={containerColor} />
            }
          />
          <Route
            path="/:id"
            element={
              <div style={{ position: 'relative' }} className="main_welcome">
                <ForSetSessionId />
                {(isAsk && !welcome || isAsk && runToFinalComponents) && <div onClick={() => navigate(`ask-speaker/${sessionId}`) // если у нас не заносится в state sessionId, то просто забираем его из URL
                } className='ask_lector'
                // style={{ bottom: questions.length && width >= 750 ? '20px' : width < 750 ? '70px' : '' }}
                >Задать вопрос лектору</div>}

                {!welcome && questions.length ? ( // кнопка выше временная история
                  <WelcomeComponent
                    id={sessionId}
                    questionNameColor={questionNameColor}
                    backgroundImage={backgroundImage}
                    buttonsColor={buttonsColor}
                    clientTextColor={clientTextColor}
                    containerColor={containerColor}
                    currentCaseName={currentCaseName}
                    blockNameColor={blockNameColor}
                    start={setWelcome}
                    isLastQuestion={cases.length - 1 == currentIndex ? true : false}
                    // система проверяет последний ли это вопрос, для того чтобы менять 
                    // текст вывода при ответе на крайний вопрос участником
                    questionsCount={
                      questions.length
                    }
                  />
                ) : questions.length ? (
                  <>
                    <div style={{ backgroundColor: containerColor, maxHeight: height * .95, marginBottom: marginForTelegramApp }} className="mainContainer">
                      {!runToFinalComponents ? (
                        <>
                          <div className="containerQuestions" style={{ opacity: resolveNextBlock ? 0 : 1, minHeight: height * .3 }}>
                            {questions[currentGlobalQuestionsIndex]?.type ===
                              modalTypes.freeAnswer ? (
                              <FreeAnswer
                                currentBlock={ // не используется
                                  questions[currentGlobalQuestionsIndex]
                                }
                                currentGlobalQuestionsIndex={
                                  currentGlobalQuestionsIndex
                                }
                              />
                            ) : questions[currentGlobalQuestionsIndex]?.type ===
                              modalTypes.questionsChoiceOfOptionsMany ? (
                              <QuestionsChoiceOfOptionsMany
                                buttonsColor={buttonsColor}
                                currentCaseName={currentCaseName}
                                variantActive={variantActive}
                                clientTextColor={clientTextColor}
                                blockNameColor={blockNameColor}
                                questionNameColor={questionNameColor}
                                currentBlock={
                                  questions[currentGlobalQuestionsIndex]
                                }
                                currentGlobalQuestionsIndex={
                                  currentGlobalQuestionsIndex
                                }
                              />
                            ) : questions[currentGlobalQuestionsIndex]?.type ===
                              modalTypes.questionsChoiceOfOptionsOne ? (
                              <QuestionsChoiceOfOptionsOne
                                buttonsColor={buttonsColor}
                                currentCaseName={currentCaseName}
                                variantActive={variantActive}
                                clientTextColor={clientTextColor}
                                currentBlock={
                                  questions[currentGlobalQuestionsIndex]
                                }
                                blockNameColor={blockNameColor}
                                questionNameColor={questionNameColor}
                                currentGlobalQuestionsIndex={
                                  currentGlobalQuestionsIndex
                                }
                              />
                            ) : questions[currentGlobalQuestionsIndex]?.type ===
                              modalTypes.questionsOnScale ? (
                              <QuestionsOnScale
                                blockNameColor={blockNameColor}
                                currentCaseName={currentCaseName}
                                buttonsColor={buttonsColor}
                                variantActive={variantActive}
                                questionNameColor={questionNameColor}
                                currentBlock={
                                  questions[currentGlobalQuestionsIndex]
                                }
                                currentGlobalQuestionsIndex={
                                  currentGlobalQuestionsIndex
                                }
                              />
                            ) : (questions[currentGlobalQuestionsIndex]?.type ===
                              modalTypes.questionsQuizOne || questions[currentGlobalQuestionsIndex]?.type ===
                              modalTypes.questionsQuizMany) ? <QuizOneOrMany betQuiz={betQuiz} blockNameColor={blockNameColor}
                                currentCaseName={currentCaseName}
                                buttonsColor={buttonsColor}
                                variantActive={variantActive}
                                questionNameColor={questionNameColor}
                                currentBlock={
                                  questions[currentGlobalQuestionsIndex]
                                }
                                currentGlobalQuestionsIndex={
                                  currentGlobalQuestionsIndex
                                }
                                editMode={editMode}
                                setEditMode={setEditMode}
                                setEditModeType={setEditModeType}
                                editModeType={editModeType}
                            /> : <QuizTFree
                              setMarginForTelegramApp={setMarginForTelegramApp}
                              betQuiz={betQuiz}
                              currentGlobalQuestionsIndex={
                                currentGlobalQuestionsIndex
                              }
                              blockNameColor={blockNameColor}
                              buttonsColor={buttonsColor}
                              variantActive={variantActive}
                              questionNameColor={questionNameColor}
                              currentBlock={
                                questions[currentGlobalQuestionsIndex]
                              }
                              editMode={editMode}
                              setEditMode={setEditMode}
                              setEditModeType={setEditModeType}
                              editModeType={editModeType}
                              checkForSendButton={checkForSendButton}
                              textAreaState={textAreaState}
                              setTextAreaState={setTextAreaState}
                            />}
                          </div>
                          <div className="nextOrPrevConsole">
                            <img style={
                              !currentGlobalQuestionsIndex
                                ? { display: "none" }
                                : {}
                            } src={require('./Images/icon_next_or_prev.png')}
                              onClick={() => {
                                if (currentGlobalQuestionsIndex > 0) {
                                  setVector("pred");
                                  setResolveNextBlock(true)
                                  setNextOrPrevQuestion(false).then(() => setResolveNextBlock(false))
                                }
                              }} />
                            {questions.length > 1 && <div className="pagination">
                              {questions.map((el, index) => {
                                return (
                                  <div
                                    onClick={() => {
                                      setCurrentGlobalQuestionsIndex(index);
                                    }}
                                    key={index}
                                    style={
                                      dontShow.find(
                                        (number) => number === index + 1
                                      ) ||
                                        (answers[index]?.list?.find(
                                          (li) =>
                                            li?.answer || li?.isChecked
                                        ) || answers[index]?.range?.choose &&
                                          index != currentGlobalQuestionsIndex) ||
                                        (answers[index]?.answer &&
                                          index != currentGlobalQuestionsIndex)
                                        ? { background: "green" }
                                        : index === currentGlobalQuestionsIndex
                                          ? { background: clientTextColor }
                                          : {}
                                    }
                                  ></div>
                                );
                              })}
                            </div>}
                            {currentGlobalQuestionsIndex < questions.length - 1 ? (
                              <img onClick={() => {
                                if (
                                  currentGlobalQuestionsIndex !=
                                  questions.length - 1
                                ) {
                                  setVector("next");
                                  setResolveNextBlock(true)
                                  setNextOrPrevQuestion(true).then(() => setResolveNextBlock(false))
                                }
                              }} src={require('./Images/icon_next_or_prev.png')} />
                            ) : (
                              <button
                                style={
                                  checkForSendButton()
                                    ? { background: buttonsColor[0], color: buttonsColor[1] }
                                    :
                                    { display: "none" }
                                }
                                className="finishButton"
                                onClick={() => {
                                  if (
                                    finalArrayLength[0].list.length ||
                                    finalArrayLength[1]?.answer.length
                                  ) {
                                    // пишем костыль
                                    if (textAreaState.content)
                                      dispatch(
                                        addFreeAnswerQuizAction(
                                          textAreaState.content,
                                          currentGlobalQuestionsIndex
                                        )
                                      );
                                      //
                                    setRunToFinalComponents(true);
                                  } else {
                                    alert("Анкета не может быть совсем пустой)");
                                  }
                                }}
                              >
                                Ответить
                              </button>
                            )}
                          </div>
                        </>
                      ) : (
                        <SenderComponent
                          setRunToFinalComponents={setRunToFinalComponents}
                          setWelcome={setWelcome}
                          currentCaseName={currentCaseName}
                          socket={socket}
                        />
                      )}
                    </div>
                  </>
                ) : cases.length ?
                  <div style={{
                    margin: '0 0 20px 0',
                    width: width <= 500 ? '95vw' : '',
                    textAlign: 'center',
                    background: containerColor,
                    padding: '10px 20px',
                    borderRadius: '10em'
                  }}>Смотрите результаты квиза на главном экране</div> : null
                }
              </div>
            }
          />
        </Routes>
      }
      {
        window.location.hash.slice(0, 7) != '#/admin' && <a
          href="https://mero.place" target="_blank"
          className="label"
        ><img src={logo} />
        </a>
      }
    </section>

  );
}

export default App;
