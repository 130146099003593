import React from "react";
import VariantComponent from "./VariantComponent";
import styles from "../../Styles.module.css";

const CurrentQuestions = ({
  index,
  blockQuestions,
  setBlockQuestions,
  questionIndex,
}) => {
  const addVariant = () => {
    if (setBlockQuestions == null) return; // для простых смертных после активации сессии редактирование невозможно
    setBlockQuestions((prev) =>
      prev.map((el, count) => {
        return count === index
          ? {
              ...el,
              questions: el.questions.map((li, num) => {
                return num === questionIndex
                  ? {
                      ...li,
                      list: [
                        ...li.list,
                        {
                          name: "",
                          isChecked: false,
                          id: el.id,
                        },
                      ],
                    }
                  : li;
              }),
            }
          : el;
      })
    );
  };

  const onChangeCurrentQuestionBlockName = (e) => {
    if (setBlockQuestions == null) return; // для простых смертных после активации сессии редактирование невозможно
    setBlockQuestions((prev) =>
      prev.map((el, count) => {
        return count === index
          ? {
              ...el,
              questions: el.questions.map((q, number) => {
                return number === questionIndex
                  ? { ...q, blockName: e.target.value }
                  : q;
              }),
            }
          : el;
      })
    );
  };

  return (
    <>
      <input
        className={styles.question_input}
        type="text"
        placeholder="Введите текст вопроса"
        value={blockQuestions[index].questions[questionIndex].blockName}
        onChange={onChangeCurrentQuestionBlockName}
      />
      <div className={styles.variants_block}>
        {blockQuestions[index].questions[questionIndex].list.map(
          (el, count) => (
            <VariantComponent
              key={count}
              blockQuestions={blockQuestions}
              setBlockQuestions={setBlockQuestions}
              index={index}
              questionIndex={questionIndex}
              listIndex={count}
            />
          )
        )}
        <div className={styles.add_variant} onClick={addVariant}>
          <img
            src={require("../../../../../Images/plus-square_variant.png")}
            alt=""
          />
          Добавить вариант ответа
        </div>
      </div>
    </>
  );
};

export default CurrentQuestions;
