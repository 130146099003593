import { combineReducers, legacy_createStore as createStore } from "redux";
import questionsState from "./QuestionsReducer";
import answersState from "./AnswersReducer";
import userState from "./UserReducer";


const reducers = combineReducers({
    questions: questionsState,
    answers: answersState,
    user: userState
});

let store = createStore(reducers);

window.store = store;

export default store;
