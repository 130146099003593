import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./Admin.module.css";
import { useNavigate } from "react-router-dom";
import { searchSessions } from "../../DAL/dal";
import { useSelector } from "react-redux";

let interval; //для того чтобы не убить сервак запросами во время поиска сессий

const AdminPanel = ({
  allSessions,
  onLoad,
  setLimit,
  totalSessions,
  height,
}) => {
  const navigate = useNavigate();
  const { role } = useSelector((state) => state.user);
  const [searchSessionArray, setSearchSessionArray] = useState([]);
  const [search, setSearch] = useState("");
  const [onLoading, setOnLoading] = useState(false); // лоад, появляющийся при поиске сессий
  const [notFound, setNotFound] = useState(true);

  const scrollFunc = (e) => {
    if (e.target.scrollHeight - e.target.offsetHeight - e.target.scrollTop <= 5)
      setLimit((prev) => prev + 5);
  };
  useEffect(() => {
    clearInterval(interval);
    setOnLoading(true);
    // отвечает за поиск сессий
    if (search) {
      interval = setTimeout(() => {
        searchSessions(search, role).then((r) => {
          setSearchSessionArray(r.data);
          setOnLoading(false);
          if (!r.data.length) setNotFound(false);
          else setNotFound(true);
        });
      }, 1500);
    } else {
      setOnLoading(false);
      setSearchSessionArray([]);
      setNotFound(true);
    }
  }, [search]);

  return (
    <section style={{ marginBottom: "auto", width: "100%" }}>
      {totalSessions > 5 && (
        <div className={styles.search_sections}>
          {/* <h3>Найти сессию</h3> */}
          <input
            type="text"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            placeholder="Введите название сессии"
          />
          {onLoading && (
            <div
              className={styles.loading}
              style={{
                position: "absolute",
                width: "30px",
                minHeight: "30px",
                right: "0",
                bottom: "5px",
              }}
            ></div>
          )}
        </div>
      )}
      {!notFound ? (
        <>
          <div style={{ width: "max-content", margin: "0 auto" }}>
            <img
              src={require("../../Images/not_found.png")}
              alt=""
              className={styles.not_found}
            />
            <h3 className={styles.h3_not_found}>Ничего не найдено</h3>
          </div>
        </>
      ) : (
        <section className={styles.adminPanelSection}>
          <div
            style={{ maxHeight: height * 0.65 }}
            onScroll={(e) => {
              if (!searchSessionArray.length) scrollFunc(e);
            }}
            className={styles.casesContainer}
          >
            {!allSessions.length && (
              <div className={styles.without_sesions_information}>
                У вас еще нет сессий <br /> с опросами
              </div>
            )}
            {!searchSessionArray.length
              ? allSessions.map((el, index) => (
                  <div className={styles.currentCaseElement} key={index}>
                    <img
                      className={styles.card_image}
                      src={el.backgroundImage}
                      alt=""
                    />
                    <div
                      style={
                        el.session_status == "активна"
                          ? { background: "green" }
                          : { background: "red" }
                      }
                      className={styles.activity_bulb}
                    ></div>
                    <div className={styles.currentCase}>
                      <div>
                        <h2>{el.sessionName}</h2>
                      </div>
                      <div>
                        <h6>{el.sessionId}</h6>
                      </div>
                      <div>
                        <span>{el.session_status}</span>
                        <span>{" • "}</span>
                        <span>{el.createdTime}</span>
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        navigate(`current-session/${el.sessionId}`);
                      }}
                      className={styles.editSessionsButton}
                    >
                      <img
                        className={styles.edit_image}
                        src={require("../../Images/Button.png")}
                        alt=""
                      />
                    </div>
                  </div>
                ))
              : searchSessionArray.map((el, index) => (
                  <div className={styles.currentCaseElement} key={index}>
                    <img
                      className={styles.card_image}
                      src={el.backgroundimage}
                      alt=""
                    />
                    <div
                      style={
                        el.session_status == "активна"
                          ? { background: "green" }
                          : { background: "red" }
                      }
                      className={styles.activity_bulb}
                    ></div>
                    <div className={styles.currentCase}>
                      <div>
                        {el.sessionname
                          .toLocaleUpperCase()
                          .indexOf(search.toLocaleUpperCase()) >= 0 ? (
                          <h2>
                            {el.sessionname.slice(
                              0,
                              el.sessionname
                                .toLocaleUpperCase()
                                .indexOf(search.toLocaleUpperCase())
                            )}
                            <span style={{ color: "yellow" }}>
                              {el.sessionname
                                .substring(
                                  el.sessionname
                                    .toLocaleUpperCase()
                                    .indexOf(search.toLocaleUpperCase()),
                                  el.sessionname
                                    .toLocaleUpperCase()
                                    .indexOf(search.toLocaleUpperCase()) +
                                    search.length
                                )
                                .toLocaleUpperCase()}
                            </span>
                            {el.sessionname.slice(
                              el.sessionname
                                .toLocaleUpperCase()
                                .indexOf(search.toLocaleUpperCase()) +
                                search.length
                            )}
                          </h2>
                        ) : (
                          <h2>{el.sessionname}</h2>
                        )}
                      </div>
                      <div>
                        <h6>{el.sessionid}</h6>
                      </div>
                      <div>
                        <span>{el.session_status}</span>
                        <span>{" • "}</span>
                        <span>{el.createdtime}</span>
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        navigate(`current-session/${el.sessionid}`);
                      }}
                      className={styles.editSessionsButton}
                    >
                      <img
                        className={styles.edit_image}
                        src={require("../../Images/Button.png")}
                        alt=""
                      />
                    </div>
                  </div>
                ))}
            {onLoad && <div className={styles.loading}></div>}
          </div>
          <button
            onClick={() => {
              navigate("create-session");
            }}
            className={styles.create_new_session}
          >
            <img
              style={{ opacity: ".6" }}
              src={require("../../Images/plus-square.png")}
              alt=""
            />
            Создать новый опрос
          </button>
        </section>
      )}
    </section>
  );
};

export default AdminPanel;
