import { useCallback, useEffect, useRef, useState } from "react";
import styles from "./scale.module.css";

const Scale = ({
  blockQuestions,
  setBlockQuestions,
  index,
  questionIndex,
  hideBlock,
}) => {
  // blockQuestions полный родительский массив вопросов
  // setBlockQuestions функция по изменению родительского массива вопросов
  // index полный индекс блока
  // questionIndex индекс конкретного вопроса

  const onChangeCurrentQuestionBlockName = (e) => {
    if (setBlockQuestions == null) return; // для простых смертных после активации сессии редактирование невозможно
    setBlockQuestions((prev) =>
      prev.map((el, count) => {
        return count === index
          ? {
              ...el,
              questions: el.questions.map((q, number) => {
                return number === questionIndex
                  ? { ...q, blockName: e.target.value }
                  : q;
              }),
            }
          : el;
      })
    );
  };

  const element = useRef();

  const [chanck, setChanck] = useState(0);
  const [scaleWidth, setScaleWidth] = useState(0);
  const [block, setBlock] = useState(0);
  const [sections, setSections] = useState(Array.apply(null, Array(10)));

  const setStars = useCallback(
    (e) => {
      return e.target.alt == "+" && chanck < 9
        ? setChanck((prev) => prev + 1)
        : chanck > 0 && e.target.alt == "-"
        ? setChanck((prev) => prev - 1)
        : null;
    },
    [chanck]
  );

  useEffect(() => {
    if (blockQuestions[index].questions[questionIndex]?.range?.range)
      setChanck(blockQuestions[index].questions[questionIndex].range.range - 1);
  }, []);

  useEffect(() => {
    setBlockQuestions((prev) =>
      prev.map((el, count) => {
        return count === index
          ? {
              ...el,
              questions: el.questions.map((q, number) => {
                return number == questionIndex
                  ? {
                      ...q,
                      range: {
                        ...q.range,
                        range: Number(chanck.toFixed(0)) + 1,
                      },
                    }
                  : q;
              }),
            }
          : el;
      })
    );
  }, [chanck]);

  useEffect(() => {
    setBlock(element.current?.clientWidth / 9);
    setScaleWidth(element.current?.clientWidth);
  }, [element, hideBlock]);

  return (
    <>
      <input
        type="text"
        value={blockQuestions[index].questions[questionIndex].blockName}
        onChange={onChangeCurrentQuestionBlockName}
        className={styles.question_input}
        placeholder="Введите текст вопроса"
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h2>Оценочный диапазон:</h2>
        {Number(chanck.toFixed(0)) < 1 ? null : (
          <div>
            от 1 до <span>{Number(chanck.toFixed(0)) + 1}</span>
          </div>
        )}
      </div>
      <div className={styles.stars}>
        <img
          style={{ cursor: "pointer" }}
          onClick={setStars}
          src={require("../../../../../Images/star.png")}
          alt="-"
        />
        <div
          style={{ minWidth: scaleWidth }}
          ref={element}
          className={styles.scale}
        >
          {sections.map((section, index) => {
            return (
              <div
                key={index}
                style={{
                  left: block * index + "px",
                  transform:
                    Number(chanck.toFixed(0)) == index ? "scale(2.1)" : "",
                }}
                className={styles.block}
              ></div>
            );
          })}
          <div
            style={{
              left: (scaleWidth / 9) * chanck.toFixed(0) - 7 + "px",
            }}
            // draggable={true}
            className={styles.dragDiv}
            onDragStart={(e) => e.dataTransfer.setDragImage(new Image(), 0, 0)}
            onDrag={(e) => {
              if (e.clientX >= 165 && e.clientX <= scaleWidth + 165) {
                setChanck((e.clientX - 165) / (scaleWidth / 9));
                e.target.style.left = e.clientX - 172 + "px";
              }
            }}
            onDragEnd={(e) => {
              e.target.style.transition = ".5s";
              setTimeout(() => {
                e.target.style.transition = "0s";
              }, 500);
              e.target.style.left =
                (scaleWidth / 9) * chanck.toFixed(0) - 7 + "px";
            }}
          ></div>
        </div>
        <img
          style={{ cursor: "pointer" }}
          onClick={setStars}
          src={require("../../../../../Images/star-bold.png")}
          alt="+"
        />
      </div>
    </>
  );
};

export default Scale;
