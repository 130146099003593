import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { setSessionIdAction } from "../../Redux/QuestionsReducer";
import { useDispatch } from "react-redux";

const ForSetSessionId = ({}) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    // console.log(2)
    dispatch(setSessionIdAction(id));
  }, []);
  // компонента только для того чтобы задиспачить id сессии для пользовательского экрана
  return <></>;
};

export default ForSetSessionId;
