import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { addSessionsOnTariff, getUserTariff } from "../../../DAL/dal";
import styles from "./users.module.css";

const CurrentUser = ({ role }) => {
  const { id } = useParams();
  const [currentTariff, setCurrentTariff] = useState({});
  const navigate = useNavigate();
  const [countOfAddSessions, setCountOfAddSessions] = useState(0);
  const [onLoad, setOnLoad] = useState(false);

  useEffect(() => {
    getUserTariff(id).then((r) => setCurrentTariff(r));
  }, []);

  const updateTariffOnServer = () => {
    setOnLoad(true);
    addSessionsOnTariff(id, countOfAddSessions)
      .then((r) => {
        setCurrentTariff((state) => ({
          ...state,
          sessions: Number(currentTariff.sessions) + countOfAddSessions,
        }));
        setCountOfAddSessions(0);
        setOnLoad(false);
      })
      .catch((e) => {
        setOnLoad(false);
        console.log(e);
      });
  };

  const changeCounter = useCallback((e) => {
    if (e.target.innerText == "⟨-") setCountOfAddSessions((prev) => prev - 1);
    if (e.target.innerText == "-⟩") setCountOfAddSessions((prev) => prev + 1);
    if (e.target.name == "counter") setCountOfAddSessions(e.target.value);
  }, []);

  useEffect(() => {
    if (countOfAddSessions == "") setCountOfAddSessions(0);
  }, [countOfAddSessions]);

  return (
    <div style={{ margin: "0 auto auto 0" }}>
      <div
        style={{ fontSize: "24px", margin: "0 0 20px 0", cursor: "pointer" }}
        onClick={() => navigate(-1)}
      >
        ←
      </div>
      <h2>
        Тариф действует до:{" "}
        {new Date(currentTariff.end_time).toLocaleDateString()}
      </h2>
      <h2>Остаток сессиий: {currentTariff.sessions}</h2>
      <div style={{ display: "flex", gap: "1.5vw", alignItems: "center" }}>
        <h2>Добавить количество сессий:</h2>
        <div className={styles.counter_block}>
          <span onClick={(e) => changeCounter(e)}>⟨-</span>
          <input
            style={{ width: "3vw", textAlign: "center" }}
            type="number"
            name="counter"
            value={countOfAddSessions}
            onChange={(e) => changeCounter(e)}
          />
          <span onClick={(e) => changeCounter(e)}>-⟩</span>
        </div>
      </div>
      {countOfAddSessions ? (
        <button
          onClick={updateTariffOnServer}
          className={styles.add_sessions_button}
        >
          {onLoad && <img src={require("../../../Images/89.gif")} />} добавить
        </button>
      ) : null}
    </div>
  );
};

export default CurrentUser;
