import React from "react";
import styles from "./quiz.module.css";
import VariantComponent from "../One_Variant/VariantComponent";

const QuizOneOrManyVariant = ({
  index,
  blockQuestions,
  setBlockQuestions,
  questionIndex,
}) => {
  const addVariant = () => {
    if (setBlockQuestions == null) return; // для простых смертных после активации сессии редактирование невозможно
    setBlockQuestions((prev) =>
      prev.map((el, count) => {
        return count === index
          ? {
              ...el,
              questions: el.questions.map((li, num) => {
                return num === questionIndex
                  ? {
                      ...li,
                      list: [
                        ...li.list,
                        {
                          name: "",
                          isChecked: false,
                          id: el.id,
                          isCorrect: false,
                        },
                      ],
                    }
                  : li;
              }),
            }
          : el;
      })
    );
  };

  const onChangeCurrentQuestionBlockName = (e) => {
    if (setBlockQuestions == null) return; // для простых смертных после активации сессии редактирование невозможно
    setBlockQuestions((prev) =>
      prev.map((el, count) => {
        return count === index
          ? {
              ...el,
              questions: el.questions.map((q, number) => {
                return number === questionIndex
                  ? { ...q, blockName: e.target.value }
                  : q;
              }),
            }
          : el;
      })
    );
  };

  const changeIsCorrectForVariant = (i) => {
    if (setBlockQuestions == null) return;
    setBlockQuestions((prev) =>
      prev.map((el, count) => {
        return count === index
          ? {
              ...el,
              questions: el.questions.map((q, number) => {
                return number === questionIndex
                  ? {
                      ...q,
                      list: q.list.map((li, num) => {
                        if (q.type === "questionsQuizOne")
                          // здесь разделяем свободный или множественный вариант
                          li.isCorrect = num == i ? li.isCorrect : false;
                        return num === i
                          ? { ...li, isCorrect: !li.isCorrect }
                          : li;
                      }),
                    }
                  : q;
              }),
            }
          : el;
      })
    );
  };

  return (
    <>
      <input
        className={styles.question_input}
        type="text"
        placeholder="Введите текст вопроса"
        value={blockQuestions[index].questions[questionIndex].blockName}
        onChange={onChangeCurrentQuestionBlockName}
      />
      <div className={styles.variants_block}>
        {blockQuestions[index].questions[questionIndex].list.map(
          (el, count) => (
            <div key={count} style={{ position: "relative" }}>
              <div
                style={
                  blockQuestions[index].questions[questionIndex].list[count]
                    .isCorrect
                    ? { background: "white" }
                    : {}
                }
                onClick={() => changeIsCorrectForVariant(count)}
                className={styles.checkIsCorrect}
              ></div>
              <VariantComponent
                blockQuestions={blockQuestions}
                setBlockQuestions={setBlockQuestions}
                index={index}
                questionIndex={questionIndex}
                listIndex={count}
              />
            </div>
          )
        )}
        <div className={styles.add_variant} onClick={addVariant}>
          <img
            src={require("../../../../../Images/plus-square_variant.png")}
            alt=""
          />
          Добавить вариант ответа
        </div>
      </div>
    </>
  );
};

export default QuizOneOrManyVariant;
