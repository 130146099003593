import React from "react";
import styles from "./quiz.module.css";

const QuizFreeText = ({
  blockQuestions,
  setBlockQuestions,
  index,
  questionIndex,
}) => {
  const changeBlockQuestions = (name, e) => {
    setBlockQuestions((prev) =>
      prev.map((el, count) => {
        if (index === count)
          el.questions.map((question, num) => {
            if (num === questionIndex)
              switch (name) {
                case "question":
                  question.blockName = e.target.value;
                  break;
                case "correct_variant":
                  question.list[0].name = e.target.value;
                  break;
                default:
                  break;
              }
            return question;
          });
        return el;
      })
    );
  };

  return (
    <div>
      <input
        className={styles.question_input}
        type="text"
        name="question"
        placeholder="Введите текст вопроса"
        value={blockQuestions[index].questions[questionIndex].blockName}
        onChange={(e) => changeBlockQuestions("question", e)}
      />
      <input
        className={styles.question_input}
        type="text"
        name="correct_variant"
        placeholder="Правильный ответ"
        value={blockQuestions[index].questions[questionIndex].list[0].name}
        onChange={(e) => changeBlockQuestions("correct_variant", e)}
      />
    </div>
  );
};

export default QuizFreeText;
