import React, { useEffect } from "react";
import style from "./welcome.module.css";
import TestParssed from "./TestPassed";

const WelcomeComponent = ({
  start,
  currentCaseName,
  containerColor,
  clientTextColor,
  buttonsColor,
  backgroundImage,
  questionNameColor,
  id,
  blockNameColor,
  questionsCount,
  isLastQuestion,
}) => {


  if (window.localStorage.getItem("cases")) {
    const arrayPassedCases = window.localStorage.getItem("cases").split(",");
    if (arrayPassedCases.find((el) => el === id + currentCaseName)) {
      return (
        <TestParssed
          containerColor={containerColor}
          clientTextColor={clientTextColor}
          questionNameColor={questionNameColor}
          questionsCount={questionsCount}
          isLastQuestion={isLastQuestion}
        />
      );
    }
  }

  return (
    <section style={{ backgroundColor: containerColor }} className={style.main}>
      <h2 style={{ color: blockNameColor }}>{currentCaseName}</h2>
      <p style={{ color: questionNameColor }}>
        {questionsCount > 1
          ? "Доступны новые вопросы"
          : "Доступен новый вопрос"}
      </p>
      <button
        style={{
          backgroundColor: buttonsColor[0],
          color: buttonsColor[1],
        }}
        onClick={() => {
          start(true);
        }}
      >
        Начать
      </button>
    </section>
  );
};

export default WelcomeComponent;
